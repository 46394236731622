<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container">
      <section class="section--listing-form">
        <client-only>
          <listing-form formProp="edit" />
        </client-only>
      </section>
    </div>
  </default-layout>
</template>

<script>
const ListingForm = () => import('@/components/listing-form/index');
import DefaultLayout from '@/layouts/default';

export default {
  name: 'listing-form-edit',
  async fetch({ store, params, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    store.dispatch('v2/listingForm/resetAllFormState');
    await store.commit('v2/listingForm/SET_LISTING_UUID', params.uuid);
  },
  components: {
    ListingForm,
    DefaultLayout,
  },
  beforeMount() {
    this.$store.commit('global/SET_LISTING_FORM', true);
  },
  methods: {},
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('general.editProperty'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
};
</script>
